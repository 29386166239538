import {saveToken, saveUsername} from "../../data/logged-in-user";

export const handleImpersonateResponse = (ctr, name, token) => {
    localStorage.clear();
    ctr.$store.commit('setUsername', null);
    ctr.$store.commit('setCartItemNumber', null);
    saveToken(token);
    saveUsername(name);
    ctr.$store.commit('setUsername', name);
    ctr.$router.push('/');
}
/*
 * Main file
 */

// Vue, Vue Router, App and Vuex store
import Vue from 'vue'
import router from './router'
// You can use the following starter router instead of the default one as a clean starting point
// import router from './router/starter'
import App from './App.vue'
import store from './store'

// Bootstrap Vue
import BootstrapVue from 'bootstrap-vue'

// Custom components
import BaseLayoutModifier from '@/components/BaseLayoutModifier'
import BaseBlock from '@/components/BaseBlock'
import BaseBackground from '@/components/BaseBackground'
import BasePageHeading from '@/components/BasePageHeading'
import BaseNavigation from '@/components/BaseNavigation'
import FullpageModal from 'vue-fullpage-modal'

// Custom directives
import clickRipple from '@/directives/clickRipple'
import toggleClass from '@/directives/toggleClass'

//Apollo graphQL
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import {HttpLink} from "apollo-link-http";
import {apolloContextHeader} from "@/data/logged-in-user";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import {ApolloLink} from "apollo-link";
import FlashMessage from "@smartweb/vue-flash-message"
import InfiniteLoading from "vue-infinite-loading";
import {serverErrors} from "./error/server-error";
import Print from "./data/print";

// Register global plugins
Vue.use(BootstrapVue)
Vue.use(VueApollo)
Vue.use(FlashMessage, {time: 2200});
Vue.use(InfiniteLoading);
Vue.use(Print);
Vue.use(FullpageModal);

// Register global components
Vue.component(BaseLayoutModifier.name, BaseLayoutModifier)
Vue.component(BaseBlock.name, BaseBlock)
Vue.component(BaseBackground.name, BaseBackground)
Vue.component(BasePageHeading.name, BasePageHeading)
Vue.component(BaseNavigation.name, BaseNavigation)

// Register global directives
Vue.directive('click-ripple', clickRipple)
Vue.directive('toggle-class', toggleClass)

// Disable tip shown in dev console when in development mode
Vue.config.productionTip = false;
const link = ApolloLink.from([
  onError(error => {
    let errors = serverErrors(error);
    if (errors.expiredToken) {
      router.push('/login').catch(() => {});
    }
  }),
  setContext(() => ({
    headers: apolloContextHeader()
  })),
  new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_ENDPOINT
  }),
]);

// Create an http link:
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  }),
});

const apolloProvider = new VueApollo({
  defaultClient: client,
})

// Craft new application
new Vue({
  store,
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
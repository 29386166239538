import { render, staticRenderFns } from "./CartContent.vue?vue&type=template&id=0fabb43d&scoped=true&"
import script from "./CartContent.vue?vue&type=script&lang=js&"
export * from "./CartContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fabb43d",
  null
  
)

export default component.exports
import gql from "graphql-tag";

export const CART_ITEM_NUMBER = gql`
 query GetCartItemNumber{
  meUser {
   cartLength
  }
 }
`

export const CHANGE_CART_CONTENT = gql`
mutation ChangeCartContent($productId: String!, $qty: Int!){
  createChangeCartContent(input: {
    productId: $productId
    quantity: $qty
  }) {
    changeCartContent {
      icon
      type
      title
      response
      availableQty
      cartLength
    }
  }
}
`

export const GET_CART_CONTENT = gql`
query GetCartContent{
  meUser {
    cart {
      item {
        _id
        qty
        product {
          _id
          name
          code
        }
        price {
          unitPrice
          tax
          price
        }
      }
      totalPrice {
        price
        unitPrice
        tax
        grossTotalPrice
      }
      transportPrice {
        price
        unitPrice
        tax
      }
      cartLength
      untilTransportFree
      getProductsFromWarehouse
    }
  }
}
`

export const CHANGE_TRANSPORT_MODE = gql`
mutation ChangeCartTransportMode($fromWarehouse: Boolean!) {
  createChangeCartTransportMode(input: {
    productFromWarehouse: $fromWarehouse
  }) {
    changeCartTransportMode {
      response
    }
  }
}
`

export const CREATE_ORDER = gql`
mutation CreteOrder($comment: String) {
  createCreateOrder(input: {
    comment: $comment
  }) {
    createOrder {
      title
      type
      icon
      response
      products
      cartLength
    }
  }
}
`
<template>
  <b-row class="w-100 align-items-center m-0">
    <b-col sm="12" v-if="!product" class="text-center">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </b-col>
    <b-form v-bind:class="{'col-12 col-md-12': !this.deleteButtonEnabled, 'col-9 col-sm-12 col-md-12 col-lg-9': this.deleteButtonEnabled}" v-if="product && quantityForm && fieldRules.quantityForm" @submit.stop.prevent="onCartActionCreated">
      <div class="form-group mb-0">
        <b-input-group :size="size">
          <b-form-input type="number" :size="size" class="form-control-alt border" v-bind:id="getProductSpecificAttributes" v-bind:name="getProductSpecificAttributes" placeholder="Cant." v-model="$v.quantityForm[getProductSpecificAttributes].$model" @keypress="attentionSaveClick"></b-form-input>
          <b-input-group-append>
            <b-button v-bind:class="{'animated wobble': saveClickAttention}" type="submit" v-bind:variant="product.qty > 0 ? 'alt-primary': 'alt-warning'" v-b-popover.hover.html.nofade.bottom="product.qty <= 0 ? '<strong>La ultima actualizare, produsul nu a fost pe stoc, dar poti incerca noul status oricand.</strong>' : ''" :size="size"><i class="fa fa-exchange-alt"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-form>
    <b-col v-if="this.deleteButtonEnabled" md="3" class="col-3 col-sm-12 cols-md-12  col-lg-3 text-center">
      <b-button variant="danger" size="sm" @click="onDeleteProductFromCart"><i class="fa fa-trash-alt"></i></b-button>
    </b-col>
  </b-row>
</template>

<script>
import {validationMixin} from "vuelidate";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import {fleshErrorMessage} from "../../../error/server-error";
import {CHANGE_CART_CONTENT} from "../../../constants/cart-graphql";
import {getPopupConfirmButtonStyle, showQtyVerifyPopup} from "../../../constants/settings";

const options = {
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-success m-1',
    cancelButton: 'btn btn-danger m-1',
    input: 'form-control'
  }
}

Vue.use(VueSweetalert2, options)

export default {
  name: "CartControl",
  mixins: [validationMixin],
  props: {
    product: null,
    size: null,
    index: null,
    value: null,
    deleteButtonEnabled: null
  },
  data () {
    return {
      fieldRules: {
        quantityForm: null,
      },
      controlValue: null,
      saveClickAttention: null,
    }
  },
  computed: {
    getProductSpecificAttributes() {
      return this.index+'-'+this.size+'-cart-item-'+this.product.code;
    },
    quantityForm: {
      get(){
        let form = {
          quantityForm: {}
        };
        form[this.getProductSpecificAttributes] = this.controlValue;

        return form;
      },
      set(value){
        this.controlValue = value;
        this.quantityForm.quantityForm[this.getProductSpecificAttributes] = value;

        return this.quantityForm.quantityForm[this.getProductSpecificAttributes];
      }
    }
  },
  validations() {
    return this.fieldRules;
  },
  methods: {
    onCartActionCreated() {
      this.$v.quantityForm.$touch();
      let value = this.$v.quantityForm[this.getProductSpecificAttributes].$model;
      if (typeof value === 'undefined' || !value || value < 0) {
        value = 0;
      }
      let qty = parseInt(value);
      let popup = showQtyVerifyPopup(this);
      this.sendChangeRequest(popup, qty);
    },
    onDeleteProductFromCart() {
      this.quantityForm[this.getProductSpecificAttributes] = null;
      let popup = showQtyVerifyPopup(this);
      this.sendChangeRequest(popup, 0);
    },
    attentionSaveClick()
    {
        this.saveClickAttention = 'animated wobble';
        setTimeout(() => this.saveClickAttention = null, 1000);
    },
    sendChangeRequest(popup, qty) {
      let parent = this;
      this.$apollo.mutate({
        mutation: CHANGE_CART_CONTENT,
        variables: {
          'productId': this.product._id,
          'qty': qty
        }
      }).then((result) => {
        parent.showServerResponsePopupType(result.data.createChangeCartContent.changeCartContent, qty);
        this.$store.commit('setCartItemNumber', result.data.createChangeCartContent.changeCartContent.cartLength);
      }).catch((error) => {
        popup.close();
        fleshErrorMessage(error, parent);
      })
    },
    showServerResponsePopupType(response, qty) {
      if (response.type === 'popup') {
        this.$swal({
          icon: response.icon,
          title: response.title,
          html: response.response,
          showCloseButton: false,
          allowOutsideClick: true,
          customClass: {
            confirmButton: getPopupConfirmButtonStyle(response.icon),
          },
        });
        if (response.icon === 'error') {
          qty = 0;
        }
        if (qty === 0) {
          this.controlValue = null;
          this.quantityForm = {};
          this.quantityForm[this.getProductSpecificAttributes] = this.controlValue;
        }
        let eventPayload = {
          code: this.product.code,
          value: qty
        };
        this.$root.$emit('cart-control-changed', eventPayload);
      }
      if (response.type === 'choice') {
        let popup = this.$swal({
          icon: response.icon,
          title: response.title,
          html: response.response,
          showCancelButton: true,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-primary m-1',
            cancelButton: 'btn btn-secondary m-1'
          },
          confirmButtonText: 'Da!',
          cancelButtonText: 'Nu',
        }).then(result => {
          if (result.value) {
            this.sendChangeRequest(popup, response.availableQty);
          } else if (result.dismiss === 'cancel') {
            this.sendChangeRequest(popup, 0);
          }
        })
      }
    }
  },
  mounted() {
    if (this.product) {
      const amount = parseInt(this.value);
      this.controlValue = amount > 0 ? amount.toString() : null;
      this.fieldRules.quantityForm = {};
      this.fieldRules.quantityForm[this.getProductSpecificAttributes] = {};
    }
    const parent = this;
    this.$root.$on('cart-control-changed', (data) => {
      if (data.code === parent.product.code) {
        parent.controlValue = data.value > 0 ? data.value : null;
      }
    });
  },
}
</script>
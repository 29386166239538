<template>
  <!-- Sidebar -->
  <!--
    Sidebar Mini Mode - Display Helper classes

    Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
    Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
    If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

    Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
    Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
    Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
    -->
  <simplebar id="sidebar" aria-label="Main Navigation">
    <slot>
      <!-- Side Header -->
      <div class="content-header bg-white-5">
        <!-- Logo -->
        <router-link to="/" class="font-w600 text-dual">
          <img v-bind:src="logo" alt="B2B platform" class="lazar-logo" />
        </router-link>
        <!-- END Logo -->

        <!-- Extra -->
        <div>
          <!-- Options -->
          <b-dropdown v-if="false" size="sm" variant="dual" class="d-inline-block ml-2" menu-class="font-size-sm smini-hide border-0" right no-caret>
            <template #button-content>
              <i class="si si-drop"></i>
            </template>
            <li>
              <!-- Color Themes -->
              <a class="dropdown-item d-flex align-items-center justify-content-between" @click.prevent="$store.commit('setColorTheme', { theme: '' })" href="#">
                <span>Default</span>
                <i class="fa fa-circle text-default"></i>
              </a>
              <a class="dropdown-item d-flex align-items-center justify-content-between" @click.prevent="$store.commit('setColorTheme', { theme: 'amethyst' })" href="#">
                <span>Amethyst</span>
                <i class="fa fa-circle text-amethyst"></i>
              </a>
              <a class="dropdown-item d-flex align-items-center justify-content-between" @click.prevent="$store.commit('setColorTheme', { theme: 'city' })" href="#">
                <span>City</span>
                <i class="fa fa-circle text-city"></i>
              </a>
              <a class="dropdown-item d-flex align-items-center justify-content-between" @click.prevent="$store.commit('setColorTheme', { theme: 'flat' })" href="#">
                <span>Flat</span>
                <i class="fa fa-circle text-flat"></i>
              </a>
              <a class="dropdown-item d-flex align-items-center justify-content-between" @click.prevent="$store.commit('setColorTheme', { theme: 'modern' })" href="#">
                <span>Modern</span>
                <i class="fa fa-circle text-modern"></i>
              </a>
              <a class="dropdown-item d-flex align-items-center justify-content-between" @click.prevent="$store.commit('setColorTheme', { theme: 'smooth' })" href="#">
                <span>Smooth</span>
                <i class="fa fa-circle text-smooth"></i>
              </a>
              <!-- END Color Themes -->

              <div role="separator" class="dropdown-divider"></div>

              <!-- Sidebar Styles -->
              <base-layout-modifier tag="a" action="sidebarStyleLight" class="dropdown-item">
                Sidebar Light
              </base-layout-modifier>
              <base-layout-modifier tag="a" action="sidebarStyleDark" class="dropdown-item">
                Sidebar Dark
              </base-layout-modifier>
              <!-- Sidebar Styles -->

              <div role="separator" class="dropdown-divider"></div>

              <!-- Header Styles -->
              <base-layout-modifier tag="a" action="headerStyleLight" class="dropdown-item">
                Header Light
              </base-layout-modifier>
              <base-layout-modifier tag="a" action="headerStyleDark" class="dropdown-item">
                Header Dark
              </base-layout-modifier>
              <!-- Header Styles -->
            </li>
          </b-dropdown>
          <!-- Options -->

          <!-- Close Sidebar, Visible only on mobile screens -->
          <base-layout-modifier size="sm" variant="dual" action="sidebarClose" class="d-lg-none ml-2">
            <i class="fa fa-times"></i>
          </base-layout-modifier>
          <!-- END Close Sidebar -->
        </div>
        <!-- END Extra -->
      </div>
      <!-- END Side Header -->

      <!-- Side Navigation -->
      <div class="content-side content-side-full">
        <base-navigation :nodes="navigation">
        </base-navigation>
      </div>
      <!-- END Side Navigation -->
      <hr v-if="false" style="border-color: rgba(255, 255, 255, 0.5);"/>
      <div class="content-side" style="color: rgba(255, 255, 255, 0.5); font-size: 13px;" v-if="false">
        <div><small><span class="text-primary">Depozit central:</span> Str. Gabor Aron, Nr. 2, Gheorgheni, 535500, Harghita<br/>
          <span class="text-primary">Cont:</span> RO05 RNCB 0155 0163 1223 0001<br/>
          <span class="text-primary">Banca:</span> BCR Gheorgheni<br/>
          <span class="text-primary">Tel:</span> 0266-362827<br/>
          <span class="text-primary">Mobil:</span> 0728-302440,<br/>0744-472280<br/>
          <span class="text-primary">Email vanzari:</span><br/>comenzi@lazarcomputers.ro<br/>
          <span class="text-primary">Email office:</span><br/>office@lazarcomputers.ro
        </small></div>
      </div>
    </slot>
  </simplebar>
  <!-- END Sidebar -->
</template>

<script>
// SimpleBar, for more info and examples you can check out https://github.com/Grsmto/simplebar/tree/master/packages/simplebar-vue
import simplebar from 'simplebar-vue'

// Get navigation data
import menuList from '@/data/menu'
import {USER_ROLE_GETTER} from "@/constants/user-graphql";
import {fleshErrorMessage} from "@/error/server-error";

export default {
  name: 'BaseSidebar',
  props: {
    classes: String
  },
  components: {
    simplebar
  },
  data () {
    return {
      // Get main navigation
      navigation: [],
      logo: require('@/assets/lazar-logo.png'),
    }
  },
  watch: {
    '$route': function() {
      this.getAvailableMenuItems();
    }
  },
  methods: {
    getAvailableMenuItems() {
      let $this = this;
      this.$apollo.query({
        query: USER_ROLE_GETTER,
        fetchPolicy: "no-cache"
      }).then((result) => {
        $this.navigation = menuList.main.filter(function (item) {
          return (item.roles &&
              item.roles.filter(value => result.data.meUser.userRole.roles.includes(value)).length > 0
          ) || !item.roles;
        });
        this.$store.commit('pageLoader', { mode: 'off' })
        this.$store.commit('setCanImpersonate', result.data.meUser.userRole.roles.includes('ROLE_SUPER_ADMIN'));
        this.$store.commit('setIsImpersonate', result.data.meUser.userRole.roles.includes('ROLE_IMPERSONATED'));
      }).catch((error) => {
        fleshErrorMessage(error);
        this.$store.commit('pageLoader', { mode: 'off' })
      });
    }
  },
  beforeMount() {
    this.$store.commit('pageLoader', { mode: 'on' });
    this.getAvailableMenuItems();
  }
}
</script>

<template>
  <div>
    <div class="content content-full" v-if="!loggedIn">
      <base-block rounded content-full>
        <div class="py-4 text-center">
          <b-row class="justify-content-center">
        <b-col md="8" lg="6" xl="4" class="py-4">
          <!-- Header -->
          <div class="text-center">
            <p class="mb-2">
              <img src="@/assets/lazar-logo.png" alt="B2B platform" />
            </p>
            <h1 class="h4 mb-1">
              Urmareste lucrearea in service
            </h1>
            <h2 class="h6 font-w400 text-muted mb-3">
              Importator si distribuitor componente notebook si accesorii PC
            </h2>
            <span v-if="formErrors != null" class="text-danger" v-text="formErrors"></span>
          </div>
          <!-- END Header -->

          <!-- Sign In Form -->
          <div v-if="dataLoad" class="text-center">
            <b-spinner variant="primary" label="Loading..."></b-spinner>
          </div>
          <b-form v-if="!dataLoad" @submit.stop.prevent="onSubmit">
            <div class="py-3">
              <div class="form-group">
                <b-form-input size="lg" class="form-control-alt" id="phoneNumber" name="phoneNumber" placeholder="Numarul de telefon" autocomplete="off" v-model="$v.form.phoneNumber.$model" :state="!$v.form.phoneNumber.$error && null" aria-describedby="phoneNumber-feedback"></b-form-input>
              </div>
              <div class="form-group">
                <b-form-input type="password" size="lg" class="form-control-alt" id="code" name="code" placeholder="Codul primit in mail" autocomplete="new-password" v-model="$v.form.code.$model" :state="!$v.form.code.$error && null" aria-describedby="code-feedback"></b-form-input>
              </div>
            </div>
            <b-row class="form-group row justify-content-center mb-0">
              <b-col md="6" xl="5">
                <b-button type="submit" variant="primary" block>
                  <i class="fa fa-fw fa-sign-in-alt mr-1"></i> Authentificare
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <!-- END Sign In Form -->
        </b-col>
      </b-row>
        </div>
      </base-block>
    </div>
    <!-- Page Content -->
    <div v-if="loggedIn" class="content">
      <!-- Hero -->
      <base-block rounded content-full>
        <h2 class="text-center" v-bind:class="'text-'+work.statusStyle">
            {{ work.statusLabel }}
        </h2>
        <b-row class="row-deck" v-if="work">
          <b-col md="3">
            <base-block title="Nume client" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-weight-bold text-primary font-size-sm">
                {{ work.clientName }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Telefon" header-class="bg-gray" class="table-bordered" themed>
              <p class="text-dark font-weight-bold font-size-sm">
                {{ work.clientPhone }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Adresa de email" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-weight-bold text-dark font-size-sm">
                {{ work.clientEmail ? work.clientEmail : '-'  }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Adresa" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-weight-bold text-dark font-size-sm">
                {{ work.clientAddress ? work.clientAddress : '-'  }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Echipament" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{ work.productName }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Serie echipament" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{ work.productSeries }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Termen de rezolvare" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{ work.resolveTermDate ? getDateStr(work.resolveTermDate) : '-' }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Situatia produsului" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                <span v-if="work.warranty">Echipamentul <strong>ESTE</strong> in garantie</span>
                <span v-if="!work.warranty">Echipamentul <strong>NU</strong> este in garantie</span>
              </p>
            </base-block>
          </b-col>
          <b-col md="6">
            <base-block title="Descrierea produsului" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{work.productDescription}}
              </p>
            </base-block>
          </b-col>
          <b-col md="6">
            <base-block title="Descrierea problemei" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{work.problemDescription}}
              </p>
            </base-block>
          </b-col>
        </b-row>
      </base-block>

      <base-block rounded>
        <b-tabs class="block" nav-class="nav-tabs-block" content-class="block-content overflow-hidden">
          <b-tab title="CRONOLOGIE" active class="fade-up">
            <ul class="timeline timeline-alt" :class="{ 'timeline-centered': timelineCentered }">
              <!-- Photos Event -->
              <li class="timeline-event" v-for="item in work.items" :key="item._id">
                <div class="timeline-event-icon bg-success">
                  <i class="fa fa-cogs"></i>
                </div>
                <base-block :title="item.actionLabel" header-bg class="timeline-event-block">
                  <template #options>
                    <div class="timeline-event-time block-options-item font-size-sm font-w600">
                      <span>{{getDateTimeStr(item.createdAt)}}</span>
                    </div>
                  </template>
                  <div class="alert alert-info d-flex align-items-center justify-content-between" role="alert" v-if="item.description">
                    <div class="flex-fill mr-3">
                      <p class="mb-0">{{item.description}}</p>
                    </div>
                  </div>
                </base-block>
              </li>
            </ul>
          </b-tab>
          <b-tab title="DEVIZ" class="fade-up" v-if="work.status === 5">
            <b-table-simple responsive bordered striped table-class="table-vcenter">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th class="d-sm-none" style="width: 80%;">Articol</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 10%;">Data</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 15%;">Tehnician</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 15%;">Actiune</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 20%;">Detalii</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 10%;">Unitate</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 10%;">Pret unitar</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 10%;">Pret</b-th>
                  <b-th class="d-none d-sm-table-cell" style="width: 10%;">TVA</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-if="work.items.filter((item) => { return [2, 3, 4].includes(item.status) }).length <= 0 && !dataLoad">
                  <b-td class="d-md-none text-center">
                    <b-alert variant="warning" show class="mt-3">
                      <p class="mb-0">
                        Nu am gasit nici un rezultat
                      </p>
                    </b-alert>
                  </b-td>
                  <b-td colspan="8" class="d-none d-sm-table-cell text-center">
                    <b-alert variant="warning" show class="mt-3">
                      <p class="mb-0">
                        Nu am gasit nici un rezultat
                      </p>
                    </b-alert>
                  </b-td>
                </b-tr>
                <b-tr v-for="item in work.items.filter((item) => { return [2, 3, 4].includes(item.status) })" :key="item._id">
                  <b-td class="d-sm-none" style="width: 15%;">
                    <div class="text-dark font-weight-bold">
                      {{ getDateTimeStr(item.createdAt) }}
                    </div>
                    <div class="text-primary font-weight-bold">
                      {{ item.technician.name }}
                    </div>
                    <div>{{ item.description }}</div>
                    <b-row>
                      <b-col class="col-6 mt-2">
                        <div><i class="fa fa-box"></i> <span>{{ item.priceModel.unity.unit }} <span>{{ item.priceModel.unity.unitLabel }}</span></span></div>
                      </b-col>
                      <b-col class="col-6 mt-2">
                        <div><i class="fa fa-money-bill"></i> <span>{{ item.priceModel.unitPrice.toFixed(2) }} <span>lei</span></span></div>
                      </b-col>
                      <b-col class="col-6 mt-2">
                        <div><i class="fa fa-dollar-sign"></i> <span>{{ item.priceModel.price.toFixed(2) }} <span>lei</span></span></div>
                      </b-col>
                      <b-col class="col-6 mt-2">
                        <div><i class="fa fa-percent"></i> <span class="text-warning">{{ item.priceModel.tax.toFixed(2) }} <span>lei</span></span></div>
                      </b-col>
                    </b-row>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm">
                    <span class="text-dark font-weight-light small">{{ getDateTimeStr(item.createdAt) }}</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm">
                    <span class="text-dark font-weight-bolder">{{ item.technician.name }}</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm">
                    <span class="text-dark font-weight-light">{{ item.actionLabel }}</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm">
                    <span class="text-dark font-weight-bold small">{{ item.description }}</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                    <span class="text-primary font-weight-bold">{{ item.priceModel.unity.unit }} ({{ item.priceModel.unity.unitLabel }})</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                    <span class="text-dark font-weight-bold">{{ item.priceModel.unitPrice.toFixed(2) }} lei</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                    <span class="text-primary font-weight-bold">{{ item.priceModel.price.toFixed(2) }} lei</span>
                  </b-td>
                  <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                    <span class="text-dark font-weight-bold">{{ item.priceModel.tax.toFixed(2) }} lei</span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="d-sm-none" style="width: 15%;">
                    <b-row>
                      <b-col class="col-12 font-weight-bold">Total:</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="col-6 text-right text-primary font-weight-bold">
                        <i class="fa fa-dollar-sign"></i> <span>{{ work.priceModel.price.toFixed(2) }} <span>lei</span></span>
                      </b-col>
                      <b-col class="col-6 text-right text-dark font-weight-bold">
                        <i class="fa fa-percent"></i> <span class="text-warning">{{ work.priceModel.tax.toFixed(2) }} <span>lei</span></span>
                      </b-col>
                    </b-row>
                  </b-td>
                  <td colspan="6" class="d-none d-sm-table-cell text-right font-weight-bold text-dark">Total</td>
                  <td class="d-none d-sm-table-cell text-center font-weight-bold text-primary">{{ work.priceModel.price.toFixed(2) }} lei</td>
                  <td class="d-none d-sm-table-cell text-center font-weight-bold text-dark">{{ work.priceModel.tax.toFixed(2) }} lei</td>
                </b-tr>
                <b-tr>
                  <b-td class="d-sm-none" style="width: 15%;">
                    <div class="text-primary font-weight-bold">Total de plata client:</div>
                    <div class="text-right text-primary font-weight-bold">{{work.priceModel.totalPrice.toFixed(2)}} lei</div>
                  </b-td>
                  <td colspan="6" class="d-none d-sm-table-cell text-right font-weight-bold text-primary">Total de plata client</td>
                  <td colspan="2" class="d-none d-sm-table-cell text-center font-weight-bold text-primary">{{work.priceModel.totalPrice.toFixed(2)}} lei</td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
        </b-tabs>
      </base-block>
      <!-- END Hero -->
    </div>
    <!-- END Page Content -->
  </div>
</template>

<script>
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import {getErrorMessage} from "../../../error/server-error";
import {saveTrackingToken, trackingToken} from "@/data/logged-in-user";
import {GET_WORK_FOR_TRACKING, TRACKING_LOGIN} from "@/constants/tracking-graphql";
import {getDateStr, getDateTimeStr} from "@/constants/helpers/common-helper";

export default {
  mixins: [validationMixin],
  data () {
    return {
      form: {
        phoneNumber: null,
        code: null
      },
      formErrors: null,
      dataLoad: false,
      work: null,
    }
  },
  validations: {
    form: {
      phoneNumber: {
        required,
        minLength: minLength(3)
      },
      code: {
        required,
        minLength: minLength(5)
      }
    }
  },
  computed: {
      loggedIn: {
        get () {
          const t = this.$store.getters.trackingLoggedIn !== null;
          if (!t) {
            this.clearData();
          }
          return t;
        },
        set (token) {
          if (!token) {
            this.work = null;
          }
          this.$store.commit('setTrackingLoggedIn', token);
        }
      }
  },
  methods: {
    onSubmit () {
      this.formErrors = null;
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.dataLoad = true;
      this.$apollo.mutate({
        mutation: TRACKING_LOGIN,
        variables: {
          'phoneNumber': this.$v.form.phoneNumber.$model,
          'code': this.$v.form.code.$model
        }
      }).then((result) => {
        this.dataLoad = false;
        this.saveData(result.data.createTrackingLogin.trackingLogin.response);
      }).catch((error) => {
        this.formErrors = getErrorMessage(error);
        this.dataLoad = false;
      })
    },
    saveData (token) {
      saveTrackingToken(token);
      this.$store.commit('setTrackingLoggedIn', token);
      this.loadData();
    },
    loadData() {
      this.$apollo.mutate({
        mutation: GET_WORK_FOR_TRACKING,
        variables: {
          'token': trackingToken()
        }
      }).then((result) => {
        this.work = result.data.createGetWorkForTracking.getWorkForTracking;
      }).catch((error) => {
        this.formErrors = getErrorMessage(error);
      });
    },
    clearData () {
      if (!this.$store.getters.trackingLoggedIn) {
        this.work = null;
      }
    },
    getDateStr: getDateStr,
    getDateTimeStr: getDateTimeStr
  },
  mounted() {
    if (this.loggedIn) {
      this.loadData();
    }
  }
}
</script>
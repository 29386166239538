export const serverErrors = (error) => {
    const errorTypes = {
        validation: [],
        business: [],
        graphql: [],
        expiredToken: false,
    };
    if (error.graphQLErrors) {
        error.graphQLErrors.every(function (item) {
            if (item.extensions.category.indexOf('validation') >= 0) {
                errorTypes.validation.push(item);
            }
            if (item.extensions.category.indexOf('businessLogic') >= 0) {
                errorTypes.business.push(item);
            }
            if (item.extensions.category.indexOf('graphql') >= 0) {
                errorTypes.graphql.push(item);
            }
        });
    }
    if(error.networkError && error.networkError.result.code === 401 && (error.networkError.result.message === "Expired JWT Token" || error.networkError.result.message === "The password was changed!")) {
        localStorage.clear();
        errorTypes.expiredToken = true;
    }

    return errorTypes;
}

export const handleFormValidation = (error, form) => {
    let errors = serverErrors(error);
    let returnValue = {};
    if (errors.validation) {
        errors.validation.every(function (item) {
            Object.keys(item.path).every(function(property) {
                if (Object.keys(form).includes(property)) {
                    returnValue[property] = item.path[property];
                }
            })
        });
    }

    return returnValue;
}

export const fleshErrorMessage = (error, ctr) => {
    let message = getErrorMessage(error);
    if (message !== "" && ctr) {
        ctr.flashMessage.error({message: message});
    }
}

export const getErrorMessage = (error) => {
    let errors = serverErrors(error);
    let message = "";
    errors.business.forEach(function (item) {
        if (message !== "") {
            message += "\n";
        }
        message += item.message;
    });
    errors.graphql.every(function (item) {
        if (message !== "") {
            message += "\n";
        }
        message += item.message;
    });

    return message;
}
import {formatDate} from "@/constants/helpers/common-helper";

export const GC_AUTH_TOKEN = 'reseller-auth-token'
export const GC_AUTH_NAME = 'reseller-auth-name'
export const GC_TRACKING_TOKEN = 'reseller-tracking-token'
export const ITEM_NUMBER_PER_PAGE = 15

export const getPopupConfirmButtonStyle = (type) => {
    let confirmButtonClass = 'btn btn-primary m-1';
    if (type === 'error') {
        confirmButtonClass = 'btn btn-danger m-1';
    }
    if (type === 'warning') {
        confirmButtonClass = 'btn btn-warning m-1';
    }

    return confirmButtonClass;
}

export const showQtyVerifyPopup = (parent) => {
    return parent.$swal({
        html: '<p>' +
            '<span role="status" class="spinner-border text-primary mt-1"> ' +
            '<span class="sr-only">Loading...</span></span>' +
            '<span class="font-weight-bold text-primary ml-3">Un moment!</span><br/>' +
            '<span class="text-muted"><small>Verificam stocul disponibil!</small></span></p>',
        showCloseButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
    });
}

export const getDateString = (date) => {
    let d = new Date(date);

    return formatDate(d)+' '+(d.getHours() < 10 ? '0' : '') + d.getHours()+':'+(d.getMinutes() < 10 ? '0' : '')+d.getMinutes();
}
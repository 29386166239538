export const getDateStr = (value) => {
    return formatDate(new Date(value));
}

export const getDateTimeStr = (value) => {
    let obj = new Date(value);

    return obj.toLocaleDateString()+' '+obj.toLocaleTimeString();
}

export const backTo = (router, path) => {
    hasHistory() ?
        router.go(-1) :
        router.push(path)
    ;
}

export const formatDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

function hasHistory() {
    return window.history.length > 2
}
<template>
  <base-layout :layout-classes="layoutClasses">
    <!-- Header Content -->
    <!-- Using the available v-slot, we can override the default Header content from layouts/partials/Header.vue -->
    <template #header>
      <!-- Header Content -->
      <div class="content-header">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Logo -->
          <img v-bind:src="logo" alt="B2B platform" class="lazar-logo" />
          <!-- END Logo -->
          <!-- END Left Section -->
        </div>
        <div class="d-flex align-items-center" v-if="loggedIn">
          <b-button @click="logout" class="btn btn-dark">
            Logout
          </b-button>
        </div>
      </div>
    </template>
    <!-- END Header Content -->
    <!-- END Navigation -->
  </base-layout>
</template>

<script>
import BaseLayout from '../Base'
import {trackingToken} from "@/data/logged-in-user";
import {GC_TRACKING_TOKEN} from "@/constants/settings";

export default {
  name: 'BackendTracking',
  components: {
    BaseLayout
  },
  data () {
    return {
      // Override and set custom CSS classes to the container of each base layout element
      layoutClasses: {
        sideOverlay: '',
        sidebar: '',
        header: '',
        footer: ''
      },
      logo: require('@/assets/lazar-logo.png'),
      navigation: [
        {
          name: 'Dashboard',
          to: '/backend-boxed/dashboard',
          icon: 'si si-compass'
        },
        {
          name: 'Pages',
          heading: true
        },
        {
          name: 'Variations',
          icon: 'si si-puzzle',
          sub: [
            {
              name: 'Simple 1',
              to: '/backend-boxed/simple1'
            },
            {
              name: 'Simple 2',
              to: '/backend-boxed/simple2'
            },
            {
              name: 'Image 1',
              to: '/backend-boxed/image1'
            },
            {
              name: 'Image 2',
              to: '/backend-boxed/image2'
            }
          ]
        },
        {
          name: 'Search',
          to: '/backend-boxed/search',
          icon: 'si si-magnifier'
        },
        {
          name: 'Go Back',
          to: '/backend/dashboard',
          icon: 'si si-action-undo'
        }
      ],
      baseSearchTerm: '',
      notifications: [
        {
          href: 'javascript:void(0)',
          icon: 'fa fa-fw fa-check-circle text-success',
          title: 'You have a new follower',
          time: '15 min ago'
        },
        {
          href: 'javascript:void(0)',
          icon: 'fa fa-fw fa-plus-circle text-info',
          title: '1 new sale, keep it up',
          time: '22 min ago'
        },
        {
          href: 'javascript:void(0)',
          icon: 'fa fa-fw fa-times-circle text-danger',
          title: 'Update failed, restart server',
          time: '15 min ago'
        },
        {
          href: 'javascript:void(0)',
          icon: 'fa fa-fw fa-plus-circle text-info',
          title: '2 new sales, keep it up',
          time: '33 min ago'
        },
        {
          href: 'javascript:void(0)',
          icon: 'fa fa-fw fa-user-plus text-success',
          title: 'You have a new subscriber',
          time: '41 min ago'
        },
        {
          href: 'javascript:void(0)',
          icon: 'fa fa-fw fa-check-circle text-success',
          title: 'You have a new follower',
          time: '42 min ago'
        }
      ]
    }
  },
  computed: {
    loggedIn: {
      get(){
        return this.$store.getters.trackingLoggedIn !== null;
      },
      set(token) {
        this.$store.commit('setTrackingLoggedIn', token);
      }
    }
  },
  methods: {
    onSubmit () {
      this.$router.push('/backend-boxed/search?' + this.baseSearchTerm)
    },
    eventHeaderSearch (event) {
      // When ESCAPE key is hit close the header search section
      if (event.which === 27) {
        event.preventDefault()
        this.$store.commit('headerSearch', { mode: 'off' })
      }
    },
    logout () {
      localStorage.removeItem(GC_TRACKING_TOKEN);
      this.$store.commit('setTrackingLoggedIn', null);
    }
  },
  mounted () {
    document.addEventListener('keydown', this.eventHeaderSearch);
    if (trackingToken()) {
      this.loggedIn = true;
    }
  },
  destroyed () {
    document.removeEventListener('keydown', this.eventHeaderSearch)
  },
  created () {
    // Set default elements for this layout
    this.$store.commit('setLayout', {
      header: true,
      sidebar: false,
      sideOverlay: false,
      footer: true
    })

    // Set various template options
    this.$store.commit('headerStyle', { mode: 'dark'})
    this.$store.commit('mainContent', { mode: 'boxed'})
  }
}
</script>

import {GC_AUTH_NAME, GC_AUTH_TOKEN, GC_TRACKING_TOKEN} from "@/constants/settings";

export const apolloContextHeader = () => {
    let headers = {};
    let token =  loggedInToken();
    if (token) {
        headers.authorization = token;
    }

    return headers;
}

export const saveToken = (token) => {
    localStorage.setItem(GC_AUTH_TOKEN, token)
}

export const saveUsername = (name) => {
    localStorage.setItem(GC_AUTH_NAME, name)
}

export const saveTrackingToken = (token) => {
    localStorage.setItem(GC_TRACKING_TOKEN, token)
}

export const trackingToken = () => {
    let token = localStorage.getItem(GC_TRACKING_TOKEN);

    return token ?? null;
}

const loggedInToken = () => {
    let token = localStorage.getItem(GC_AUTH_TOKEN);
    token = (token) ? 'Bearer ' + token : null;

    return token;
}
<template>
  <!-- Header -->
  <header id="page-header">
    <slot>
      <!-- Header Content -->
      <div class="content-header">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <base-layout-modifier action="sidebarToggle" size="sm" variant="dual" class="mr-2 d-lg-none">
            <i class="fa fa-fw fa-bars"></i>
          </base-layout-modifier>
        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div class="d-flex align-items-center">
          <avatar v-if="username != null" v-bind:fullname="username" :size="40" :radius="50" color="#5c80d1"></avatar>
          <!-- User Dropdown -->
          <b-dropdown size="sm" variant="dual" class="d-inline-block ml-2" menu-class="p-0 border-0 font-size-sm" right no-caret>
            <template #button-content>
              <span class="d-none d-sm-inline-block ml-1" v-text="username"></span>
              <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
            </template>
            <li>
              <div class="p-3 text-center bg-primary">
                <avatar v-if="username != null" v-bind:fullname="username" :size="40" :radius="50" color="#575757"></avatar>
              </div>
              <div class="p-2">
                <router-link class="dropdown-item d-flex align-items-center justify-content-between" to="/user/profile">
                  <span>Profil</span>
                  <span>
                    <i class="si si-user ml-1"></i>
                  </span>
                </router-link>
                <router-link class="dropdown-item d-flex align-items-center justify-content-between" to="/user/setting">
                  <span>Setari</span>
                  <i class="si si-settings"></i>
                </router-link>
                <router-link v-if="$store.state.canImpersonate" class="btn-link dropdown-item d-flex align-items-center justify-content-between" to="/user/impersonate">
                  <span>Cont client</span>
                  <i class="si si-shuffle ml-1"></i>
                </router-link>
                <a v-if="$store.state.isImpersonate" href="javascript:void(0);" class="btn-link dropdown-item d-flex align-items-center justify-content-between"  v-on:click="cancelImpersonate">
                  <span>Cont SuperAdmin</span>
                  <i class="si si-shuffle ml-1"></i>
                </a>
                <div role="separator" class="dropdown-divider"></div>
                <a href="javascript:void(0);" class="btn-link dropdown-item d-flex align-items-center justify-content-between" v-on:click="logout">
                  <span>Log Out</span>
                  <i class="si si-logout ml-1"></i>
                </a>
              </div>
            </li>
          </b-dropdown>
          <!-- END User Dropdown -->

          <!-- Toggle Side Overlay -->
          <base-layout-modifier action="sideOverlayToggle" variant="dual" size="sm" class="ml-2">
            <i v-bind:class="{ blink: cartItemNumber > 0 }" class="fa fa-fw fa-shopping-cart"></i>
            <span v-if="cartItemNumber > 0" class="badge badge-primary badge-pill ml-2">{{ cartItemNumber }}</span>
          </base-layout-modifier>
          <!-- END Toggle Side Overlay -->
        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->

      <!-- Header Loader -->
      <div id="page-header-loader" class="overlay-header bg-white" :class="{ 'show': $store.state.settings.headerLoader }">
        <div class="content-header">
          <div class="w-100 text-center">
            <i class="fa fa-fw fa-circle-notch fa-spin"></i>
          </div>
        </div>
      </div>
      <!-- END Header Loader -->
    </slot>
  </header>
  <!-- END Header -->
</template>

<script>
import Avatar from 'vue-avatar-component';
import {CART_ITEM_NUMBER} from "../../constants/cart-graphql";
import {fleshErrorMessage} from "../../error/server-error";
import {CANCEL_IMPERSONATE} from "../../constants/user-graphql";
import {handleImpersonateResponse} from "../../constants/helpers/impersonate-helper";

export default {
  name: 'BaseHeader',
  props: {
    classes: String
  },

  data () {
    return {
      baseSearchTerm: '',
    }
  },
  components: {Avatar},
  computed: {
    username () {
      return this.$store.getters.username;
    },
    cartItemNumber () {
      return this.$store.getters.cartItemNumber;
    }
  },
  methods: {
    onSubmit () {
      this.$router.push('/backend/pages/generic/search?' + this.baseSearchTerm)
    },
    eventHeaderSearch (event) {
      // When ESCAPE key is hit close the header search section
      if (event.which === 27) {
        event.preventDefault()
        this.$store.commit('headerSearch', { mode: 'off' })
      }
    },
    logout () {
      localStorage.clear();
      this.$store.commit('setUsername', null);
      this.$store.commit('setCartItemNumber', null);
      this.$router.push('/login');
    },
    cancelImpersonate() {
      this.$apollo.mutate({
        mutation: CANCEL_IMPERSONATE
      }).then((result) => {
        this.flashMessage.info({message: "Login in contul SuperAdmin rezolvat cu succes!"});
        handleImpersonateResponse(this, result.data.createCancelImpersonate.cancelImpersonate.name, result.data.createCancelImpersonate.cancelImpersonate.response);
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
  },
  mounted () {
    document.addEventListener('keydown', this.eventHeaderSearch);
    if (!this.$store.getters.cartItemNumber) {
      this.$apollo.query({
        query: CART_ITEM_NUMBER,
        fetchPolicy: "no-cache"
      }).then((result) => {
        this.$store.commit('setCartItemNumber', result.data.meUser.cartLength);
      }).catch((error) => {
        fleshErrorMessage(error);
      });
    }
  },
  destroyed () {
    document.removeEventListener('keydown', this.eventHeaderSearch)
  }
}
</script>

import gql from 'graphql-tag';

export const ME_USER_FOR_PROFILE = gql`
  query GetUserProfile{
    meUser {
       _id
       firstName
       lastName
       name
       email
       company {
         fiscalCode
         registrationNumber
         country
       }
       companyHeadquarter {
         name
         county
         locality
         country
         address
         phone
         email
       }
    }
  }
`
export const UPDATE_PERSONAL_DATA_MUTATION = gql`
  mutation ChangePersonalData($firstName: String!, $lastName: String!, $email: String!){
    createChangeUserPersonalData(input: {
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
    }) {
      changeUserPersonalData {
        response
      }
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $plainPassword: String!) {
    createUserChangePassword(input: {
      currentPassword: $currentPassword
      plainPassword: $plainPassword
    }) {
      userChangePassword {
        response
      }
    }
  }
`
export const USER_LIST = gql`
query UserList {
  meUser {
    company {
      enabledModuleChoices
      user {
        id
        _id
        firstName
        lastName
        email
        companyHeadquarter {
          name
        }
        userRole {
          enabledModuleNameList
        }
        admin
        active
      }
    }
  }
}
`

export const USER_COMPANY_DETAILS_FOR_ADD = gql`
query UserList {
  meUser {
    company {
      enabledModuleChoices
      headquarters {
        id
        _id
        name
      }
    }
  }
}
`

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $password: String!, $headquarterID: String!, $enabledModules: Iterable){
    createCreateUser(input: {
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      plainPassword: $password,
      headquarterId: $headquarterID,
      enabledFeatureList: $enabledModules
    }) {
      createUser {
        response
      }
    }
  }
`

export const USER_COMPANY_DETAILS_FOR_EDIT = gql`
query UserList($userId: String!) {
  meUser {
    company {
      enabledModuleChoices
      headquarters {
        id
        _id
        name
      }
      user(filter_id: $userId) {
        _id
        firstName
        lastName
        name
        email
        active
        admin
        companyHeadquarter {
          id
          _id
          name
        }
        userRole {
          enabledModuleIdentifierList
        }
      }
    }
  }
}   
`
export const USER_ROLE_GETTER = gql`
query CheckRole{
  meUser {
    _id
    userRole {
      roles,
    }
  }
}
`
export const EDIT_USER_MUTATION = gql`
  mutation EditUser($userId: String!, $firstName: String!, $lastName: String!, $email: String!, $headquarterID: String!, $password: String, $enabledModules: Iterable){
    createEditUser(input: {
      userId: $userId,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      headquarterId: $headquarterID,
      plainPassword: $password,
      enabledFeatureList: $enabledModules
    }) {
      editUser {
        response
      }
    }
  }
`

export const CHANGE_USER_STATUS = gql`
mutation ChangeUserStatus($userId: String!)
{
  createChangeUserStatus(input:{userId: $userId}) {
    changeUserStatus {
      response
    }
  }
}
`

export const USER_SELECT_OPTIONS = gql`
query GetUserOptions($company: String!, $name: String){
  users(filter_company: $company, filter_name: $name) {
    value: _id
    label: name
  }
}
`

export const IMPERSONATE = gql`
mutation Impersonate($userId: String!){
  createImpersonate(input: {
    userId: $userId
  }) {
    impersonate {
      name
      response
    }
  }
}
`

export const CANCEL_IMPERSONATE = gql`
mutation CancelImpersonate {
  createCancelImpersonate(input: {}) {
    cancelImpersonate {
      name
      response
    }
  }
} 
`

import gql from "graphql-tag";

export const TRACKING_LOGIN = gql`
    mutation CreateTrackingLogin($phoneNumber: String!, $code: String!)
    {
        createTrackingLogin(input: {
            phoneNumber: $phoneNumber
            code: $code
        }) {
            trackingLogin {
                response
            }
        }
    }
`;

export const GET_WORK_FOR_TRACKING = gql`
    mutation GetWorkForTracking($token: String!)
    {
        createGetWorkForTracking(input: {
            token: $token
        }) {
            getWorkForTracking {
                id
                _id
                clientName
                clientPhone
                code
                clientAddress
                clientEmail
                clientAddress
                productName
                productSeries
                productDescription
                problemDescription
                finishedOn
                warranty
                resolveTermDate
                resolveTermDateString
                statusLabel
                statusStyle
                status
                price
                loginCode
                priceModel {
                    totalPrice
                    price
                    tax
                    productOutcomes
                    profit
                }
                investmentPrice
                options {
                    statusOptions
                    companyTechnicians
                    formFields
                    formFieldLabels
                }
                items {
                    _id
                    description
                    createdAt
                    status
                    price
                    boughtPrice
                    unit
                    technician {
                        name
                        id
                        _id
                    }
                    actionLabel
                    priceModel {
                        totalPrice
                        price
                        tax
                        unitPrice
                        unity {
                            unit
                            unitLabel
                        }
                    }
                }
            }
        }
    }
`
<template>
  <div>
    <div class="mb-4">
      <b-alert v-if="cart.untilTransportFree > 0" variant="info" size="lg" show class="text-center mb-3">
        Pentru <span class="font-weight-bold">transport gratuit</span> mai ai de comandat marfa in valoare de <span class="font-weight-bold" v-text="cart.untilTransportFree"></span> lei fara TVA!
      </b-alert>
      <b-alert v-if="cart.untilTransportFree <= 0 && cart.totalPrice && !cart.getProductsFromWarehouse" variant="success" size="lg" show class="text-center mb-3">
        Beneficiezi de <span class="font-weight-bold">transport gratuit</span>!
      </b-alert>
      <h3 class="block-title mb-4">Produse</h3>
      <b-alert v-if="(!cart.item || cartItemNumber <= 0)" variant="warning" size="lg" show v-text="'Nu ai selectat nici un produs'" class="text-center"></b-alert>
      <b-table-simple v-if="cart.item && cart.cartLength > 0 && cartItemNumber > 0" responsive bordered striped table-class="table-vcenter">
        <b-thead head-variant="dark">
          <b-tr>
            <b-th class="d-sm-none" style="width: 100%;">Articole</b-th>
            <b-th class="d-none d-sm-table-cell" style="width: 15%;">Cod Produs</b-th>
            <b-th class="d-none d-sm-table-cell" style="width: 25%;">Denumire produs</b-th>
            <b-th class="d-none d-sm-table-cell text-center" style="width: 21%">Cant.</b-th>
            <b-th class="d-none d-sm-table-cell text-center" style="width: 15%;">Pret unitar</b-th>
            <b-th class="d-none d-sm-table-cell text-center" style="width: 14%;">Valoare</b-th>
            <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">TVA</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in cart.item" :key="item._id">
            <b-td class="d-sm-none">
              <div>
                <span class="text-dark font-weight-bolder">{{ item.product.code }}</span>
                <span class="ml-3"> - </span>
                <span class="text-primary ml-3">{{ item.product.name }}</span>
              </div>
              <b-row class="font-size-sm">
                <b-col class="col-4 mt-2">
                  <div><i class="fa fa-piggy-bank"></i> <span class="text-info">{{ item.price.unitPrice.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
                <b-col class="col-4 mt-2">
                  <div><i class="fa fa-dollar-sign"></i> <span>{{ item.price.price.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
                <b-col class="col-4 mt-2">
                  <div><i class="fa fa-percent"></i> <span class="text-warning">{{ item.price.tax.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col class="offset-5 col-7">
                  <CartControl delete-button-enabled="true" index="2" size="sm" v-bind:value="item.qty" v-bind:product="item.product"></CartControl>
                </b-col>
              </b-row>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
              <span class="text-dark font-weight-bolder">{{ item.product.code }}</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-size-sm">
              <span class="text-primary">{{ item.product.name }}</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell text-center">
              <CartControl delete-button-enabled="true" index="1" size="md" v-bind:value="item.qty" v-bind:product="item.product"></CartControl>
            </b-td>
            <b-td class="d-none d-sm-table-cell text-center">
              <span class="text-muted">{{item.price.unitPrice.toFixed(2)}} RON</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell text-center">
              <span class="text-primary">{{item.price.price.toFixed(2)}} RON</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell text-center border-left-0">
              <span class="text-warning">{{item.price.tax.toFixed(2)}} RON</span>
            </b-td>
          </b-tr>
          <b-tr v-if="cart.transportPrice">
            <b-td class="d-sm-none">
              <b-row class="font-size-sm">
                <b-col class="col-4">
                  <span class="text-info font-weight-bolder">Transport</span>
                </b-col>
                <b-col class="col-4">
                  <div><i class="fa fa-dollar-sign"></i> <span class="text-info font-w600">{{ cart.transportPrice.price.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
                <b-col class="col-4">
                  <div><i class="fa fa-percent"></i> <span class="text-info font-w600">{{ cart.transportPrice.tax.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
              </b-row>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-size-md text-right font-w600" colspan="4">
              <span class="info text-info">Cheltuieli transport</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-size-md text-center font-w600">
              <span class="text-info">{{ cart.transportPrice.price.toFixed(2) }} RON</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-size-md text-center font-w600">
              <span class="text-info">{{ cart.transportPrice.tax.toFixed(2) }} RON</span>
            </b-td>
          </b-tr>
          <b-tr v-if="cart.totalPrice">
            <b-td class="d-sm-none">
              <b-row class="font-size-sm">
                <b-col class="col-4">
                  <span class="text-dark font-weight-bolder">Total</span>
                </b-col>
                <b-col class="col-4">
                  <div><i class="fa fa-dollar-sign"></i> <span class="text-dark font-w600">{{ cart.totalPrice.price.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
                <b-col class="col-4">
                  <div><i class="fa fa-percent"></i> <span class="text-dark font-w600">{{ cart.totalPrice.tax.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
              </b-row>
            </b-td>
            <b-td class="d-none d-sm-table-cell text-right font-w600 font-size-md" colspan="4">
              <span class="info text-dark">Total</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-w600 font-size-md text-center">
              <span class="text-dark">{{ cart.totalPrice.price.toFixed(2) }} RON</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-w600 font-size-md text-center">
              <span class="text-dark">{{ cart.totalPrice.tax.toFixed(2) }} RON</span>
            </b-td>
          </b-tr>
          <b-tr v-if="cart.totalPrice">
            <b-td class="d-sm-none">
              <b-row class="font-size-h4 font-w600 text-primary">
                <b-col class="col-6">
                  <span class="text-primary font-size-h4 font-w600">Total de plata</span>
                </b-col>
                <b-col class="col-6">
                  <div class="text-right"><span>{{ cart.totalPrice.grossTotalPrice.toFixed(2) }} <span>RON</span></span></div>
                </b-col>
              </b-row>
            </b-td>
            <b-td class="d-none d-sm-table-cell text-right font-w600 font-size-lg" colspan="4">
              <span class="text-primary">Total de plata</span>
            </b-td>
            <b-td class="d-none d-sm-table-cell font-w600 font-size-lg text-center" colspan="2">
              <span class="text-primary">{{ cart.totalPrice.grossTotalPrice.toFixed(2) }} RON</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-row v-if="cart.item && cart.cartLength > 0" class="mt-4">
        <b-col v-if="saturdayOrder" class="font-w600 col-7 offset-5 col-sm-4 offset-sm-8 col-md-3 offset-md-9 col-lg-2 offset-lg-10">
          <b-form-checkbox switch stacked id="saturday-delivery" name="saturday-delivery"><span class="mt-1">Livrare sambata</span></b-form-checkbox>
        </b-col>
        <b-col class="col-4">
          Comentariu:
        </b-col>
        <b-col class="font-w600 col-7 offset-1 col-sm-4 offset-sm-4 col-md-3 offset-md-5 col-lg-2 offset-lg-6">
          <b-form-checkbox switch stacked id="without-transport" name="without-transport" @change="onChangeTransportMode" :checked="cart.getProductsFromWarehouse"><span class="mt-1">Ridicare din depozit</span></b-form-checkbox>
        </b-col>
        <b-form class="col-md-12 mt-4" v-if="cart" @submit.stop.prevent="onOrderSend">
          <div class="form-group mb-0">
            <b-textarea name="comment" id="cart-comment" class="form-control-alt border" placeholder="Introduceti comentariul aici..." v-model="$v.orderForm.comment.$model" :state="!$v.orderForm.comment.$error && null"></b-textarea>
          </div>
          <div class="form-group mb-2 mt-4 text-center">
            <b-button type="submit" variant="primary"><i class="fa fa-check-circle"></i> Corect, trimite comanda</b-button>
          </div>
        </b-form>
      </b-row>
    </div>
  </div>
</template>

<script>
import {CHANGE_TRANSPORT_MODE, CREATE_ORDER, GET_CART_CONTENT} from "../../../constants/cart-graphql";
import {fleshErrorMessage} from "../../../error/server-error";
import CartControl from "./CartControl";
import {validationMixin} from "vuelidate";
import {getPopupConfirmButtonStyle, showQtyVerifyPopup} from "../../../constants/settings";

export default {
  name: "CartContent",
  mixins: [validationMixin],
  components: {CartControl},
  data () {
    return {
      cart: {},
      orderForm: {
        comment: null,
      },
      saturdayOrder: null,
    }
  },
  validations: {
    orderForm: {
      comment: {}
    }
  },
  computed: {
    cartItemNumber () {
      return this.$store.getters.cartItemNumber;
    },
  },
  watch: {
    cartItemNumber: function () {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.$apollo.query({
        query: GET_CART_CONTENT,
        fetchPolicy: "no-cache",
      }).then((result) => {
        this.cart = result.data.meUser.cart;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      });
    },
    onChangeTransportMode(value) {
      this.$apollo.mutate({
        mutation: CHANGE_TRANSPORT_MODE,
        variables: {
          fromWarehouse: value
        }
      }).then((result) => {
        if (result.data.createChangeCartTransportMode.changeCartTransportMode.response === 'ok') {
          this.loadData();
        }
      }).catch((error) => {
        fleshErrorMessage(error, this);
      });
    },
    onOrderSend() {
      this.$v.orderForm.$touch();
      if (this.$v.orderForm.$anyError) {
        return;
      }
      let popup = showQtyVerifyPopup(this);
      this.$apollo.mutate({
        mutation: CREATE_ORDER,
        variables: {
          'comment': this.$v.orderForm.comment.$model
        }
      }).then((response) => {
        this.processServerResponse(response.data.createCreateOrder.createOrder)
      }).catch((error) => {
        fleshErrorMessage(error, this);
        popup.close();
      })
    },
    processServerResponse(response) {
      let parent = this;
      if (response.type === 'popup') {
        this.$swal({
          title: response.title,
          html: response.response,
          icon: response.icon,
          showCloseButton: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          customClass: {
            confirmButton: getPopupConfirmButtonStyle(response.icon),
          },
        });
        if (response.icon === 'success') {
          this.$store.commit('sideOverlay', { mode: 'close' });
          this.$store.commit('setCartItemNumber', 0);
          let parent = this;
          this.cart.item.forEach(function (item) {
            let eventPayload = {
              code: item.product.code,
              value: 0
            };
            parent.$root.$emit('cart-control-changed', eventPayload);
          });
          this.orderForm.comment = null;
        }
        if (response.icon === 'warning') {
          this.$store.commit('setCartItemNumber', response.cartLength);
          response.products.forEach(function (item) {
            let eventPayload = {
              code: item.code,
              value: item.qty
            };
            parent.$root.$emit('cart-control-changed', eventPayload);
          });
        }
      }
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
<template>
  <!-- Footer -->
  <footer id="page-footer">
    <slot>
      <div class="content py-3">
        <b-row class="font-size-sm">
          <b-col sm="6" order-sm="2" class="py-1 text-center text-sm-right">
            Implementat cu <i class="fa fa-heart text-danger"></i> de <span class="text-primary">B.SZ</span>
          </b-col>
          <b-col sm="6" order-sm="1" class="py-1 text-center text-sm-left">
            <span class="text-primary font-weight-bold">{{ $store.getters.appName + ' ' + $store.getters.appVersion }}</span> &copy; {{ $store.getters.appCopyright }}
          </b-col>
        </b-row>
      </div>
    </slot>
  </footer>
  <!-- END Footer -->
</template>

<script>
export default {
  name: 'BaseFooter',
  props: {
    classes: String
  }
}
</script>

function addStyles (win, styles) {
    styles.forEach(style => {
        let link = win.document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', style);
        win.document.getElementsByTagName('head')[0].appendChild(link);
    });
}

const Print = {
    install(Vue) {
        Vue.prototype.$print = (content, cb = () => true) => {
            let defaultName = '_blank',
                defaultSpecs = 'fullscreen=yes,titlebar=yes,scrollbars=yes',
                defaultStyles = []
            let name = defaultName,
                specs = defaultSpecs,
                styles = defaultStyles
            ;

            //specs = !specs.length ? specs.join(',') : '';
            const url = '';
            const win = window.open(url, name, specs);

            win.document.write(
                content
            );

            addStyles(win, styles);

            setTimeout(() => {
                win.document.close();
                win.focus();
                win.print();
                win.close();
                cb();
            }, 1000);

            return true;
        }
    }
};

export default Print;